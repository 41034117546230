@font-face {
  font-family: "ti";
  src: url('../fonts/tawazonIcon/ti.eot?t=1655777262549'); /* IE9*/
  src: url('../fonts/tawazonIcon/ti.eot?t=1655777262549#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("../fonts/tawazonIcon/ti.woff2?t=1655777262549") format("woff2"),
  url("../fonts/tawazonIcon/ti.woff?t=1655777262549") format("woff"),
  url('../fonts/tawazonIcon/ti.ttf?t=1655777262549') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('../fonts/tawazonIcon/ti.svg?t=1655777262549#ti') format('svg'); /* iOS 4.1- */
}

[class^="ti-"], [class*=" ti-"] {
  font-family: 'ti' !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ti-app-name:before { content: "\ea01"; }
.ti-apple:before { content: "\ea02"; }
.ti-arrow-down:before { content: "\ea03"; }
.ti-at:before { content: "\ea04"; }
.ti-bird:before { content: "\ea05"; }
.ti-check-rounded:before { content: "\ea06"; }
.ti-check-small:before { content: "\ea07"; }
.ti-check:before { content: "\ea08"; }
.ti-close:before { content: "\ea09"; }
.ti-exit:before { content: "\ea0a"; }
.ti-facebook:before { content: "\ea0b"; }
.ti-gift:before { content: "\ea0c"; }
.ti-google:before { content: "\ea0d"; }
.ti-happy:before { content: "\ea0e"; }
.ti-left-arrow:before { content: "\ea0f"; }
.ti-lock:before { content: "\ea10"; }
.ti-logout:before { content: "\ea11"; }
.ti-lotus-flower:before { content: "\ea12"; }
.ti-mail:before { content: "\ea13"; }
.ti-open-lock:before { content: "\ea14"; }
.ti-premium-Icon:before { content: "\ea15"; }
.ti-radio-active:before { content: "\ea16"; }
.ti-refresh:before { content: "\ea17"; }
.ti-right-arrow:before { content: "\ea18"; }
.ti-user:before { content: "\ea19"; }

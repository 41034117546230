[dir='ltr'] .start-5{
    left: 1.25rem;
}

[dir='rtl'] .start-5{
    right: 1.25rem;
}

[dir='ltr'] .end-5{
    right: 1.25rem;
}

[dir='rtl'] .end-5{
    left: 1.25rem;
}

.svg-white * {
    fill: white;
    color: white;
}

.svg-fill-primary * {
    fill: #472C7A;
    color: #472C7A;
}
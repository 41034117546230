.Alert {
    position: relative;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 8px;
}

.AlertPrimary {
    color: #fff;
    background-color: #0162e8;
    border-color: #0162e8;
}

.AlertSuccess {
    color: #1f5c01;
    background-color: #d8efcc;
    border-color: #c8e9b8;
}

.AlertInfo {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.AlertWarning {
    color: #856404;
    background-color: #fbbc0b;
    border-color: #ffeeba;
}

.AlertDanger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-MediumItalic.eot');
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
    url('../fonts/montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-MediumItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-Bold.eot');
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-Medium.eot');
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-Regular.eot');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-Light.eot');
    src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Light.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE SS Two';
    src: url('../fonts/ge_ss_two/subset-GESSTwoLight-Light.woff') format('woff'),
    url('../fonts/ge_ss_two/subset-GESSTwoLight-Light.ttf') format('truetype'),
    url('../fonts/ge_ss_two/subset-GESSTwoLight-Light.svg#GESSTwoLight-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE SS Two';
    src: url('../fonts/ge_ss_two/subset-GESSTwoBold-Bold.woff') format('woff'),
    url('../fonts/ge_ss_two/subset-GESSTwoBold-Bold.ttf') format('truetype'),
    url('../fonts/ge_ss_two/subset-GESSTwoBold-Bold.svg#GESSTwoBold-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE SS Two';
    src: url('../fonts/ge_ss_two/subset-GESSTwoMedium-Medium.woff') format('woff'),
    url('../fonts/ge_ss_two/subset-GESSTwoMedium-Medium.ttf') format('truetype'),
    url('../fonts/ge_ss_two/subset-GESSTwoMedium-Medium.svg#GESSTwoMedium-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Start Custom Fonts CSS */
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 100;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-Thin.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 200;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-ExtraLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 800;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-ExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Madani Arabic';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url('../fonts/madani/Madani-Arabic-Black.ttf') format('truetype');
}
/* End Custom Fonts CSS */
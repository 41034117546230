.Button {
    display: inline-block;
    min-height: 68px;
    font-weight: 400;
    color: #031b4e;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 8px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.ButtonAbsoluteHeight  {
    min-height: initial;
}

.RoundedButton {
    border-radius: 100px;
}

.FittedButton {
    min-height: initial;
    padding: 0.375rem 1.25rem;
}

.Button + .Button {
    margin-right: 0.5rem;
}

.Button:disabled {
    opacity: 0.65;
}

.ButtonIcon {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.Button:disabled {
    background-color: #BDBDCA;
    border-color: #BDBDCA;
    cursor: default;
}

.Button:disabled:hover {
    background-color: #BDBDCA;
    border-color: #BDBDCA;
}

.ButtonLoading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ButtonPrimary {
    color: #fff;
    background-color: #472C7A;
    border-color: #472C7A;
}

.ButtonPrimary:hover {
    color: #fff;
    background-color: #3f276d;
    border-color: #3f276d;
}

.ButtonPlain {
    border: 2px solid #3f276d
}

.ButtonPlain:hover {
    color: #fff;
    background-color: #3f276d;
    border-color: #3f276d;
}

.ButtonSecondary {
    color: #fff;
    background-color: #B39EDC;
    border-color: #B39EDC;
}

.ButtonSecondary:hover {
    color: #fff;
    background-color: #7c58c2;
    border-color: #7c58c2;
}

.ButtonSuccess {
    color: #fff;
    background-color: #22c03c;
    border-color: #22c03c;
}

.ButtonSuccess:hover {
    color: #fff;
    background-color: #2e8a01;
    border-color: #2a7d01
}

.ButtonWarning {
    color: #fff;
    background-color: #fbbc0b;
    border-color: #fbbc0b;
}

.ButtonWarning:hover {
    color: #fff;
    background-color: #e0a800;
    border-color: #d39e00;
}

.ButtonDanger {
    color: #fff !important;
    background-color: #ee335e;
    border-color: #ee335e;
}

.ButtonDanger:hover {
    color: #fff;
    background-color: #f95374;
    border-color: #f95374;
}

.ButtonInfo {
    color: #fff;
    background-color: #00b9ff;
    border-color: #00b9ff;
}

.ButtonInfo:hover {
    color: #fff;
    background-color: #03a8e6;
    border-color: #00b9ff;
}

.ButtonLight {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #dce1ec;
}

.ButtonLight:hover {
    color: #242f48;
    background-color: #dce1ec;
    border-color: #dce1ec;
}

.ButtonDark {
    color: #fff;
    background-color: #364261;
    border-color: #364261;
}

.ButtonDark:hover {
    color: #fff;
    background-color: #2d374b;
    border-color: #283143;
}
@import "./assets/css/ti.css";
@import './assets/css/fonts.css';
@import './assets/css/utilities.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: #f7fafe;
}

[dir='ltr'] body {
    font-family: "Poppins", sans-serif;
}

[dir='rtl'] body {
    font-family: 'Montserrat', "Madani Arabic", sans-serif;
}

.ltr {
    direction: ltr;
}

.bg-default {
    /*background: rgb(192,134,255);*/
    /*background: -moz-linear-gradient(180deg, rgba(192,134,255,1) 0%, rgba(0,152,206,1) 100%, rgba(192,134,255,1) 192%);*/
    /*background: -webkit-linear-gradient(180deg, rgba(192,134,255,1) 0%, rgba(0,152,206,1) 100%, rgba(192,134,255,1) 192%);*/
    /*background: linear-gradient(180deg, rgba(192,134,255,1) 0%, rgba(0,152,206,1) 100%, rgba(192,134,255,1) 192%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c086ff",endColorstr="#c086ff",GradientType=1);*/
}
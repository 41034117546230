.Card {
    box-shadow: 0 23px 30px -20px rgba(71, 44, 122, 0.06);
    border: 1px solid #EDF1F6;
}

.CardWithTabs {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

[dir='ltr'] .CardWithTabs {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.Tab {
    background: rgba(255, 255, 255, 0.76);
    border: 1px solid #EDF1F6;
    border-top: #EDF1F6 solid 5px;
    box-sizing: border-box;
    border-radius: 10px;
    color: #42406C;
    position: relative;
    border-bottom: none;
}

@media (min-width: 576px) {
    .Tab {
        border-radius: 10px 10px 0 0;
    }
}

.Tab.active, .Tab:hover {
    color: #8264BB;
    border-top: #8264BB solid 5px;
}
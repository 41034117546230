.AppContent {
    background: #F6F9FE;
    min-height: 100vh;
    margin-bottom: 0 !important;
    /* -webkit-transition: margin-left 0.3s ease; */
    -o-transition: margin-left 0.3s ease;
    /* transition: margin-left 0.3s ease; */
    overflow: hidden;

    padding-top: 63px;
    flex-basis: 100%;
}